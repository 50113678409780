
import React, { useEffect } from "react";
import ReactGA from 'react-ga';

import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TelegramLoginButton from "react-telegram-login";
import TableData from '../src/components/TableData';
import ResponsiveAppBar from '../src/components/Header';
import Footer from '../src/components/Footer';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
// import Paper from '@mui/material/Paper';

import { useCookies } from 'react-cookie';
ReactGA.initialize('UA-250709130-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [cookies, setCookie] = useCookies('');

  const onAuth = async (response) => {
    console.log(response, 'response');
    setCookie('userid', response.username, { path: '/' });
    setCookie('firstname', response.first_name, { path: '/' });
    setCookie('bot_id', response.id, { path: '/' });
    setCookie('hash_id', response.hash, { path: '/' });

    const Cookies = window.Cookies;

    Cookies.set('userid', response.username, { path: '/' });
    Cookies.set('firstname', response.first_name, { path: '/' });
    Cookies.set('bot_id', response.id, { path: '/' });
    console.log(Cookies.get('bot_id')); // Pacman
  };

  // const logout = () => {
  //   location.href='/auth/logout?bot_id='+cookies.bot_id+'&origin=https://oauth.telegram.org&request_access=write&hash='+cookies
  // }
  const theme = createTheme({

  });
  useEffect(() => {


  }, []);
  return (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <ResponsiveAppBar firstName={cookies.firstname} />
      <Container maxWidth="lg" >
        <Box  sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 110px)',

        flexGrow: 1, mt: 5
      }}  className='widgettelegram'>
      {cookies.userid ? 
      <Box>
{/* klk1236_bot monolertbot */}
          <TableData userNameTelegram={'@' + cookies.userid} telegramId={cookies.bot_id} /> 
          </Box>
        : 
        <>
        <Card sx={{ width: {xs:'auto', sm:500, lg: 500}, padding: 5, mt: 5, mx: 'auto' }}
          className="telegramloginwidget">
          <p className="monodes">Monoalert is your one-stop destination for monitoring all your validators across multiple networks.</p>
          {/* <h1 className="logintext">Login</h1> */}
          <h4 className="subtext">Please check the steps before using the app</h4>
          <p>&emsp;<span>Step1:</span>&nbsp;Connect Telegram </p>
          <p>&emsp;<span>Step2:</span>&nbsp;Add your favourite  validator's</p>
          <p>&emsp;<span>Step3:</span>&nbsp;Connect <a href="https://t.me/monolertbot" rel="noreferrer" target="_blank" className="subscribetextinfo">MonolertBot</a> to your chat</p>
          <TelegramLoginButton botName="monolertbot" dataOnauth={onAuth} /> </Card></>}

<Footer />
</Box>

</Container>

    </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
