import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';
import logo from '../assets/logo.png';


const ResponsiveAppBar = (props) => {
  return (
    <AppBar position="static"  style={{background: '#fff'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Typography
          style={{width: 150}}
            variant="h6"
            noWrap
            component="a"
            href="https://prithvidevs.in/"
            target='_blank'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              width: '75%'
            }}
          >
              <img style={{width: 150}} src={logo} alt='logo'/>
          </Typography>
        
          
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img style={{width: 150}} src={logo} alt='logo'/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            
          </Box>
{props.firstName ?
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={props.firstName}>
              <IconButton  sx={{ p: 0 }}>
                <Avatar alt={props.firstName} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
          </Box> : null }
        </Toolbar>

      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
