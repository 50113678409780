import * as React from 'react';
// import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography
      align="center"
      color="#fff"
      component="p" variant="body2" >
      {'Copyright © '}
      <Link color="inherit" target="_blank" rel="noopener noreferrer" href="https://prithvidevs.in/">
        PrithviDevs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {
  return (


    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'transparent'
      }}
    >
      <Container maxWidth="sm">
        <div class="icon">
          <i class="fa fa-facebook-f"></i>
        </div>
        <div class="icon">
          <i class="fa fa-youtube"></i>
        </div>
        <div class="icon">
          <i class="fa fa-twitter"></i>
        </div>
        <div class="icon">
          <i class="fa fa-instagram"></i>
        </div>
        <Copyright />
      </Container>
    </Box>
  );
}