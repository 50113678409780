import React, { useState, useEffect } from "react";
import axios from "axios";
import CreateIcon from "@mui/icons-material/Create";
import {
  Button, Snackbar, Table,
  TableBody, TableCell, TableHead, TableRow, TextField
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { API_URL } from "../constants/config";


import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableContainer from '@mui/material/TableContainer';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from '@mui/material/Alert';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import DialogTitle from "@mui/material/DialogTitle";
import akashicon from '../assets/akash_white.jpg';
import amicon from '../assets/AM.png';
import comdexicon from '../assets/comdex.png';
import xprticon from '../assets/persistence.jpeg';
import senticon from '../assets/sentinel.png';
import omniflixicon from '../assets/omniflix.jpg';
import kiicon from '../assets/ki.png';
import rebus from '../assets/rebus.png';
import passage from '../assets/passage.png';
import cosmos from '../assets/cosmos.png';
import osmosis from '../assets/osmosis.png';

const theme = createTheme({
  typography: {
    "fontFamily": `"Poppins", " sans-serif"`,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.MuiSelect-select":{
          display:"flex",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#28469D"
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          background: "radial-gradient(100% 311.1% at 97.81% 0, #fff8f8 0, #f0fdff 100%, #f0fdff 0)"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {

        root: {
          '&.MuiOutlinedInput.Mui-disabled': {
            display:"flex",
          }

        }
      }
    },
    MuiButton: {
      styleOverrides: {

        root: {
          '&.Mui-disabled': {
            color: "rgb(40 70 157 / 40%)"
          }

        }
      }
    },

    MuiButtonBase: {
      styleOverrides: {

        root: {
          '&.Mui-button.button-updateright': {
            color: "#28469D"
          }

        }
      }
    },


    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #28469D",

        }
      }
    }




  },
  palette: {
    primary: {
      light: '#28469D',
      main: '#28469D',
      dark: '#28469D',
      contrastText: '#28469D',
    },
    secondary: {
      light: '#28469D',
      main: '#28469D',
      dark: '#28469D',
      contrastText: '#28469D',
    },
  },
});


function TableData(props) {
  // Creating style object
  // const classes = useStyles();

  // Defining a state named rows
  // which we can update by calling on setRows function

  console.log(props.telegramId, 'username')
  const [rows, setRows] = useState([]);
  // const [backupRows, setBackupRows] = React.useState([]);

  // Initial states
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [cancelButtonShow, setCancelButtonShow] = React.useState(false);
  const [deletedRow, setDeletedRow] = React.useState(0);
  const [disabled, setDisabled] = useState(true);
  const [updateText, setUpdateText] = useState(false);
  const [data, setData] = useState([]);
  const [updateRecord, setUpdateRecord] = useState();
  const [inputDisable, setInputDisable] = useState(true);

  const [addDisabled, setAddDisabled] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [noRecordsDisplay, setNoRecordsDisplay] = useState();

  // const [data, setData] = React.useState([]);

  // const [errorMessage, setErrorMessage] = React.useState(false);
  useEffect(() => {
    axios.get(`${API_URL}/validators/telegram/` + props.userNameTelegram)
      .then(res => {
        const data = res.data;

        console.log(data, 'data')
        if (data.result === null) {
          setEdit(true);
          setNoRecordsDisplay(false);


        } if (data.result.length) {
          setNoRecordsDisplay(true);
          const newData = data.result.sort(function (a, b) {
            const networkA = (a.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
            const networkB = (b.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
            return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
          });

          setRows(newData.reverse());
          // setRows(data.result)
        }
      })
  }, [props]);
  // Function For closing the alert snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setDeleteOpen(false);
  };

  // Function For adding new row object
  const handleAdd = () => {
    console.log(rows);
    setRows([
      ...rows,
      {
        address: "",
        network: ""
      },
    ]);
    // setEdit({
    //   [row.id]:true,
    // })

    setNoRecordsDisplay(true);

    setCancelButtonShow(true);
    setEdit(true);
    setDisabled(true);
    setUpdateDisabled(false);
    setSubmitDisabled(false);
    setAddDisabled(true);
    setInputDisable(true);
  };

  // Function to handle edit
 
  const handleCancel = () => {
    axios.get(`${API_URL}/validators/telegram/` + props.userNameTelegram)
      .then(res => {
        const data = res.data;
        console.log(data, 'data')
        if (data.result === null) {
          setEdit(true);

        } if (data.result.length) {
          const newData = data.result.sort(function (a, b) {
            const networkA = (a.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
            const networkB = (b.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
            return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
          });

          setRows(newData.reverse());
          // setRows(data.result)
        }
      })
    setSubmitDisabled(false);
    setUpdateDisabled(false);

    setEdit(false)
    setCancelButtonShow(false)
    setAddDisabled(false);
  }
  const handleUpdate = () => {
    // const filterData = rows.filter((obj, i) => {
    //   return (obj.id === backupRows[i].id && (obj.network !== backupRows[i].network || obj.address !== backupRows[i].address));
    // });
    const filterData = {
      "id": updateRecord.id,
      "operator_address": updateRecord.operator_address,
      "moniker": updateRecord.moniker,
      "telegram_handle": props.userNameTelegram,
      "consensus_address": JSON.stringify(updateRecord.consensus_address),
      "telegram_chat_id": parseInt(props.telegramId)
    }
    axios({
      method: 'put',
      url: `${API_URL}/validator`,
      data: filterData, // you are sending body instead

    }).then((response) => {
      console.log(response);
      axios.get(`${API_URL}/validators/telegram/` + props.userNameTelegram)
        .then(res => {
          const data = res.data;
          const newData = data.result.sort(function (a, b) {
            const networkA = (a.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
            const networkB = (b.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
            return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
          });

          setRows(newData.reverse());
          // setRows(data.result)
        })
    });
    setUpdateText(true);
    setEdit(false);
    setUpdateDisabled(false);
    setCancelButtonShow(false);
    setOpen(true);
    setAddDisabled(false);
    console.log("edited details", filterData)
  }
  // Function to handle save
  const handleSave = () => {

    setEdit(!isEdit);
    setRows(rows);
    console.log("saved : ", rows);
    const filterData = rows.filter((obj) => {
      return !obj.id;
    });
    const data = {
      "info": filterData.length ? filterData : rows,
      "telegram_handle": props.userNameTelegram,
      "telegram_chat_id": parseInt(props.telegramId),
    }
    console.log(data.info, 'info')
    const formatAddress = data.info.map((item) => {
      // console.log(JSON.parse(item['address']),'addressdata')
      item['address'] = JSON.parse(item['address']);
      // item['address'] = JSON.stringify(item['address.consensus_pubkey']);
      // item.address['network'] = item.network;
      return ({
        "operator_address": item.address.operator_address,
        "network": item.address.network,
        "moniker": item.address.moniker,
        "consensus_address": JSON.stringify(item.address.consensus_address)
      })

    });

    data['info'] = formatAddress;

    // const data =  {"info":rows,

    // telegram_handle:props.userNameTelegram}
    axios({
      method: 'post',
      url: `${API_URL}/validator`,
      data: data, // you are sending body instead

    }).then((response) => {
      console.log(response);
      axios.get(`${API_URL}/validators/telegram/` + props.userNameTelegram)
        .then(res => {
          const data = res.data;
          console.log(data, 'data')
          if (!data.result.length) {
            setEdit(true);
          } else {
            const newData = data.result.sort(function (a, b) {
              const networkA = (a.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
              const networkB = (b.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
              return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
            });

            setRows(newData.reverse());
            // setRows(data)
          }
        })
    });
    setSubmitDisabled(false);
    setCancelButtonShow(false);
    setOpen(true);
    setAddDisabled(false);
  };


  const handleCustomChange = (e, index) => {
    console.log(JSON.parse(e.target.value), 'customChange')
    setUpdateRecord(JSON.parse(e.target.value))
    setUpdateDisabled(true);
  }
  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes 
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {


    console.log(e.target.value, 'items')
    const persistenceURL = "https://rest.core.persistence.one/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const akashURl = "https://akash-api.polkachu.com/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const assetMantleURl = "https://rest.assetmantle.one/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const comdexURl = "https://rest.comdex.one/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const sentURL = "https://lcd-sentinel.keplr.app/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const flixURL = "https://api.omniflix.nodestake.top/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const kiURL = "https://api-kichain-ia.cosmosia.notional.ventures/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const rebusURL = "https://api.rebuschain.com:1317/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const passageURL = "https://api.passage.vitwit.com/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const cosmosURL = "https://api-cosmoshub-ia.cosmosia.notional.ventures/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const osmosisURL = "https://api-osmosis-ia.cosmosia.notional.ventures/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    if (e.target.value === 'osmosis-1') {
      axios.get(osmosisURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'cosmoshub-4') {
      axios.get(cosmosURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'passage-1') {
      axios.get(passageURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'reb_1111-1') {
      axios.get(rebusURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'kichain-2') {
      axios.get(kiURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'omniflixhub-1') {
      axios.get(flixURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'sentinelhub-2') {
      axios.get(sentURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'core-1') {
      axios.get(persistenceURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (e.target.value === 'akashnet-2') {
      axios.get(akashURl).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
        console.log(newData, 'newData')

      });
    }
    if (e.target.value === 'mantle-1') {
      axios.get(assetMantleURl).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());

      });

    }
    if (e.target.value === 'comdex-1') {
      axios.get(comdexURl).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());

      });
    }
    // setDisable(false);
    const { name, value } = e.target;

    const list = [...rows];
    const removedObject = list[e]

    console.log(removedObject, 'update address')

    list[index][name] = value;
    setRows(list);
    console.log(list, 'list')
    setSubmitDisabled(true);
    setInputDisable(false);
    // list.map((row) => {
    //   let length = 0;
    //   switch (row.network) {
    //     case 'akashvaloper': length = 51;
    //       break;
    //     case 'mantlevaloper': length = 52;
    //       break;
    //     case 'comdexvaloper': length = 52;
    //       break;
    //     case 'kivaloper': length = 48;
    //       break;
    //     case 'omniflixvaloper': length = 54;
    //       break;
    //     case 'pasgvaloper': length = 50;
    //       break;
    //     case 'persistencevaloper': length = 57;
    //       break;
    //     case 'sentvaloper': length = 50;
    //       break;
    //     default: length = 0;
    //   }


    //   if (row.network !== '' && row.address.indexOf(row.network) === 0) {
    //     console.log('valid')
    //     row['isValid'] = '';
    //     setDisableButton(false)
    //     // setErrorMessage('');
    //     if (length !== row.address.length) {
    //       row['isValid'] = 'Invalid Length';
    //       setDisableButton(true)

    //     }
    //   }
    //   else {
    //     // setErrorMessage('invalid');
    //     row['isValid'] = 'Invalid Address';
    //     console.log('invalid')
    //     // setDisableButton(true)


    //   }
    //   return true;
    // });

  };
  const handleConfirmDelete = (i) => {
    setSubmitDisabled(false);
    setDeletedRow(i);
    setShowDeleteConfirm(true);
  }


  const handleSingleEdit = (i, row) => {
    setEdit({
      [row.id]: true,
    })
    console.log('rownetwork', row.network)
    // setDeletedRow(i);
    const persistenceURL = "https://rest.core.persistence.one/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const akashURl = "https://akash-api.polkachu.com/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const assetMantleURl = "https://rest.assetmantle.one/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const comdexURl = "https://rest.comdex.one/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const sentURL = "https://lcd-sentinel.keplr.app/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const flixURL = "https://api.omniflix.nodestake.top/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const kiURL = "https://api-kichain-ia.cosmosia.notional.ventures/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const rebusURL = "https://api.rebuschain.com:1317/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const passageURL = "https://api.passage.vitwit.com/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const cosmosURL = "https://api-cosmoshub-ia.cosmosia.notional.ventures/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    const osmosisURL = "https://api-osmosis-ia.cosmosia.notional.ventures/cosmos/staking/v1beta1/validators?pagination.limit=1000";
    if (row.network === 'osmosis-1') {
      axios.get(osmosisURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });
        setData(newData.reverse());
      });
    }
    if (row.network === 'cosmoshub-4') {
      axios.get(cosmosURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });
        setData(newData.reverse());
      });
    }
    if (row.network === 'passage-1') {
      axios.get(passageURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });
        setData(newData.reverse());
      });
    }
    if (row.network === 'reb_1111-1') {
      axios.get(rebusURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });
        setData(newData.reverse());
      });
    }
    if (row.network === 'kichain-2') {
      axios.get(kiURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });
        setData(newData.reverse());
      });
    }
    if (row.network === 'omniflixhub-1') {
      axios.get(flixURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (row.network === 'sentinelhub-2') {
      axios.get(sentURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (row.network === 'core-1') {
      axios.get(persistenceURL).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (row.network === 'akashnet-2') {
      axios.get(akashURl).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (row.network === 'mantle-1') {
      axios.get(assetMantleURl).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }
    if (row.network === 'comdex-1') {
      axios.get(comdexURl).then((response) => {
        const validatorData = response.data.validators;
        const newData = validatorData.sort(function (a, b) {
          const networkA = (a.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
          const networkB = (b.description.moniker.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
          return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
        });

        setData(newData.reverse());
      });
    }

    setUpdateDisabled(true);
    console.log(row, 'network')
    const list = [...rows];
    console.log(list[i], 'removedOj')
    // setSingleUpdateRecord(list[i]);
    // setShowUpdateForm(true);
  }
  // Showing delete confirmation to users
  const handleConfirm = (i) => {
    setDeletedRow(i);
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where 
  // user click yes delete a specific row of id:i
  const handleRemoveDeleteClick = () => {
    const list = [...rows];
    const removedObject = list[deletedRow]
    if (removedObject.address === '') {
      axios.get(`${API_URL}/validators/telegram/` + props.userNameTelegram)
        .then(res => {
          setCancelButtonShow(true);
          const data = res.data;
          console.log(data, 'dat waaa')
          if (!data.result.length) {
            setEdit(true);
          } else {
            const newData = data.result.sort(function (a, b) {
              const networkA = (a.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
              const networkB = (b.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
              return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
            });

            setRows(newData.reverse());
            // setRows(data)
          }
        })
    } else {
      axios({
        method: 'delete',
        url: `${API_URL}/validator`,
        data: { "address": removedObject.address, "id": removedObject.id, telegram_handle: props.userNameTelegram }, // you are sending body instead

      }).then((response) => {
        console.log(response);
        axios.get(`${API_URL}/validators/telegram/` + props.userNameTelegram)
          .then(res => {
            const data = res.data;
            console.log(data, 'data')
            if (data.result === null) {
              setNoRecordsDisplay(false);
              setRows([]);
            } if (data.result.length) {
              setNoRecordsDisplay(true);
              const newData = data.result.sort(function (a, b) {
                const networkA = (a.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
                const networkB = (b.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
                return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
              });

              setRows(newData.reverse());
              // setRows(data.result)
            }
          })
        setDeleteOpen(true)
      });
    }
    //  list.splice(i, 1);
    //  setRows(list);
    console.log(list, 'list')
    setShowDeleteConfirm(false);
  }
  const handleRemoveClick = () => {
    const list = [...rows];
    const removedObject = list[deletedRow]
    console.log(removedObject, 'removedOj')
    axios({
      method: 'delete',
      url: `${API_URL}/validator`,
      data: { "address": removedObject.address, "id": removedObject.id, "telegram_handle": props.userNameTelegram }, // you are sending body instead

    }).then((response) => {
      console.log(response);
      axios.get(`${API_URL}/validators/telegram/` + props.userNameTelegram)
        .then(res => {
          const data = res.data;
          if (data.result === null) {
            setNoRecordsDisplay(false);
            setRows([]);
          } if (data.result.length) {
            setNoRecordsDisplay(true);
            const newData = data.result.sort(function (a, b) {
              const networkA = (a.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');
              const networkB = (b.operator_address.toUpperCase()).replace(/[^a-zA-Z0-9]/g, '');;
              return (networkA > networkB) ? -1 : (networkA < networkB) ? 1 : 0;
            });

            setRows(newData.reverse());
            // setRows(data.result)
          }

        })
      setDeleteOpen(true)
    });
    // list.splice(i, 1);
    // setRows(list);
    // console.log(list, 'list')
    setShowConfirm(false);
  };

  // Handle the case of delete confirmation 
  // where user click no 
  const handleNo = () => {
    setShowConfirm(false);
    // setShowUpdateForm(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          {updateText ? <Alert onClose={handleClose} severity="success">
            Successfully updated!
          </Alert> : <Alert onClose={handleClose} severity="success">
            Validator address saved successfully!
          </Alert>}

        </Snackbar>
        <Snackbar
          open={deleteOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Validator address deleted successfully!
          </Alert>


        </Snackbar>
        <>
          <div style={{ marginBottom: '10px' }}>
            {isEdit ? (
              <>
                <Button onClick={handleAdd} className={'button-update'} disabled={addDisabled}>
                  <AddBoxIcon onClick={handleAdd} />
                  ADD
                </Button>
              
              </>
            ) : (
              <div>
                <Button onClick={handleAdd} className={'button-update'}>
                  <AddBoxIcon onClick={handleAdd} />
                  ADD
                </Button>
               
              </div>
            )}
          </div>
        </>
        <TableContainer component={Paper} style={{ width: '100%' }}>
          <Table aria-label="simple table" style={{ width: '100%' }} md={{ minWidth: '100%' }}>
            <TableHead >
              <TableRow >
                <TableCell sx={{
                  borderBottom: "1px solid #28469D",
                  color: "#28469D",
                  fontWeight: "500"

                }}>Network Name</TableCell>
                <TableCell sx={{
                  borderBottom: "1px solid #28469D",
                  color: "#28469D",
                  fontWeight: "500"

                }}>Validator Name</TableCell>
                <TableCell sx={{
                  borderBottom: "1px solid #28469D",
                  color: "#28469D",
                  fontWeight: "500"


                }} align="left">Operator Address</TableCell>
                <TableCell sx={{
                  borderBottom: "1px solid #28469D",
                  color: "#28469D",
                  fontWeight: "500"


                }} align="left">&nbsp;</TableCell>

              </TableRow>
            </TableHead>
            <TableBody >
              {noRecordsDisplay ?
                <>
                  {rows.map((row, i) => {
                    return (
                      <>
                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          {isEdit[row.id] || !row.id ? (
                            <>

                              <TableCell align="left" sx={{ minWidth: 120 }}>
                                <FormControl sx={{ m: 1, minWidth: 180 }}>
                                  <InputLabel id="demo-simple-select-helper-label">Select Network</InputLabel>


                                  <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Select Network"
                                    value={row.network}
                                    name="network"
                                    disabled={row.id && disabled}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <MenuItem value="">
                                      <em>None</em></MenuItem>

                                    <MenuItem value="cosmoshub-4"><img src={cosmos} alt="cosmos" className='iconclass'/>&nbsp;Cosmos</MenuItem>
                                    <MenuItem value="osmosis-1"><img src={osmosis} alt="osmosis" className='iconclass'/>&nbsp;Osmosis</MenuItem>
                                    <MenuItem value="akashnet-2"><img src={akashicon} alt="akash" className='iconclass'/>&nbsp;Akash</MenuItem>
                                    <MenuItem value="mantle-1"><img src={amicon} alt="assetmantle" className='iconclass'/>&nbsp;Asset Mantle</MenuItem>
                                    <MenuItem value="comdex-1"><img src={comdexicon} alt="comdex" className='iconclass'/>&nbsp;Comdex</MenuItem>
                                    <MenuItem value="core-1"><img src={xprticon} alt="persistence" className='iconclass'/>&nbsp;Persistence</MenuItem>
                                    <MenuItem value="sentinelhub-2"><img src={senticon} alt="sentinel" className='iconclass'/>&nbsp;Sentinel</MenuItem>
                                    <MenuItem value="omniflixhub-1"><img src={omniflixicon} alt="omniflix" className='iconclass'/>&nbsp;Omniflix</MenuItem>
                                    <MenuItem value="kichain-2"><img src={kiicon} alt="kichain" className='iconclass'/>&nbsp;KI Chain</MenuItem>
                                    <MenuItem value="reb_1111-1"><img src={rebus} alt="rebus" className='iconclass'/>&nbsp;Rebus</MenuItem>
                                    <MenuItem value="passage-1"><img src={passage} alt="passage" className='iconclass'/>&nbsp;Passage</MenuItem>
                                  </Select>
                                </FormControl>

                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.moniker}
                              </TableCell>
                              {updateDisabled ?
                                <TableCell component="th" scope="row">
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Operator Address</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      label="Operator Address"
                                      margin="normal"
                                      required
                                      fullWidth
                                      value={row.address}
                                      name="address"
                                      onChange={(e) => handleCustomChange(e, i)}

                                      input={<OutlinedInput label="Operator Address" />}

                                    >
                                      {data.map((validatorname, i) => {
                                        return (
                                          <MenuItem key={i}
                                            value={'{"id":"' + row.id + '","network":"' + row.network + '","operator_address":"' + validatorname.operator_address + '","moniker": "' + validatorname.description.moniker + '","consensus_address":' + JSON.stringify(validatorname.consensus_pubkey) + "}"}

                                          // value={validatorname.operator_address}
                                          >

                                            {validatorname.description.moniker}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </FormControl>
                                </TableCell> :
                                <>
                                  {!row.id || isEdit[row.id] ?
                                    <TableCell component="th" scope="row">
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-helper-label">Operator Address</InputLabel>
                                        <Select  sx={{ width: {xs:300, sm:'auto', lg: 'auto'}}}
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper"
                                          label="Operator Address"
                                          margin="normal"
                                          required
                                          fullWidth
                                          value={row.moniker}
                                          name="address"
                                          onChange={(e) => handleInputChange(e, i)}
                                          disabled={inputDisable}

                                          input={<OutlinedInput label="Operator Address" />}
                                        >

                                          {data.map((validatorname, i) => {
                                            return (
                                              <MenuItem  sx={{ width: {xs:300, sm:'auto', lg: 'auto'}}} key={i}
                                                value={'{"network":"' + row.network + '","operator_address":"' + validatorname.operator_address + '","moniker": "' + validatorname.description.moniker + '","consensus_address":' + JSON.stringify(validatorname.consensus_pubkey) + "}"}
                                              //  value={validatorname.operator_address}
                                              >
                                                {validatorname.description.moniker}
                                              </MenuItem>
                                            )
                                          })}
                                        </Select>
                                      </FormControl>
                                    </TableCell> :
                                    <TableCell component="th" scope="row">
                                      <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Operator Address"
                                        value={row.operator_address}
                                        name="address"
                                        onChange={(e) => handleInputChange(e, i)}
                                        autoFocus
                                        disabled={row.id && disabled}
                                      // error={row.isValid}
                                      // helperText={row.isValid}

                                      />

                                    </TableCell>}
                                </>
                              }
                            </>
                          ) : (
                            <>
                              <TableCell component="th" scope="row" style={{ textTransform: 'capitalize' }}>
                                {row.network === "akashnet-2" ?
                                <span>Akashnet</span>:null}
                                {row.network === "comdex-1" ?
                                <span>Comdex</span>:null}
                                {row.network === "mantle-1" ?
                                <span>Mantle</span>:null}
                                 {row.network === "core-1" ?
                                <span>Persistence</span>:null}
                                 {row.network === "sentinelhub-2" ?
                                <span>Sentinel</span>:null}
                                 {row.network === "omniflixhub-1" ?
                                <span>Omniflix</span>:null}
                                 {row.network === "kichain-2" ?
                                <span>KI Chain</span>:null}
                                  {row.network === "reb_1111-1" ?
                                <span>Rebus</span>:null}
                                 {row.network === "passage-1" ?
                                <span>Passage</span>:null}
                                 {row.network === "cosmoshub-4" ?
                                <span>Cosmos</span>:null}
                                {row.network === "osmosis-1" ?
                                <span>Osmosis</span>:null}
                              </TableCell>
                              <TableCell component="th" align="left" scope="row" >
                                {row.moniker}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.operator_address}
                              </TableCell>

                            </>
                          )}
                          {isEdit ? (
                            <TableCell component="th" scope="row">
                              {row.network && row.operator_address ?
                                <Button disabled={row.id} className="mr10 button-updateright" onClick={() => handleConfirmDelete(i)}>

                                  <ClearIcon style={{ visibility: "hidden" }} />
                                </Button> : null}
                            </TableCell>
                          ) : (
                            <TableCell component="th" scope="row" style={{ display: 'flex' }}>
                              <Button className="mr10 button-updateright" onClick={() => handleConfirm(i)}>
                                <DeleteOutlineIcon />
                              </Button>
                              <Button align="right" onClick={() => handleSingleEdit(i, row)} className={'button-updateright'}>
                                <CreateIcon />

                              </Button>
                            </TableCell>
                          )}

                        </TableRow>
                      </>
                    );
                  })}</> : <TableRow><TableCell>No Records Found</TableCell></TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        {!addDisabled ?
          <h5 className="text-highlight">Please <a href="https://t.me/monolertbot" rel="noreferrer" target="_blank" className="subscribetext">click here</a> to subscribe a Telegram bot before Add or Submit</h5> : null}
        {showConfirm && (
          <div>
            <Dialog
              open={showConfirm}
              onClose={handleNo}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Delete"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure to delete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleRemoveClick()}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
                <Button
                  onClick={handleNo}
                  color="primary"
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        {showDeleteConfirm && (
          <div>
            <Dialog
              open={showDeleteConfirm}
              onClose={handleNo}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Delete"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure to delete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleRemoveDeleteClick()}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
                <Button
                  onClick={handleNo}
                  color="primary"
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        <Box align='center' sx={{ mt: 2 }}>
          {submitDisabled ?
            <Button
              type="submit"
              onClick={handleSave}
              variant="outlined"

              className={'button-customcolor'}
            >
              Submit
            </Button> : null}

          &emsp;{updateDisabled ?
            <Button
              type="submit"

              onClick={handleUpdate}
              variant="outlined"
              className={'button-customcolor'}
            >
              Submit
            </Button> : null}
          &emsp;
          {updateDisabled || cancelButtonShow ?
            <Button
              type="submit"
              onClick={handleCancel}
              variant="outlined"
              className={'button-customcolor'}
            >
              Cancel
            </Button> : null}
        </Box>
      </>
    </ThemeProvider>

  );
}

export default TableData;